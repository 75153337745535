import { createContext, useContext, useState } from "react"
import * as Yup from "yup"
import { cnpj } from "cpf-cnpj-validator"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"


const clientAuthSchema = () => Yup.object().shape({
    cnpj: Yup.string()
      .test(
        "cnpjValidation",
        "CNPJ invalido!",
        item => cnpj.isValid(item)
      )
      .required("CNPJ é obrigatorio!"),
    password: Yup.string()
      .required("Senha é obrigatoria!"),
})

const ClientAuthContext = createContext(null)

const ClientAuthProvider = ({ children }) => {

  const [showLogin, setShowLogin] = useState(false);
  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);
    
  const {
        register,
        getValues,
        handleSubmit,
        reset,
        formState: {errors},
  } = useForm( { resolver: yupResolver(clientAuthSchema()) } )


  return (
      <ClientAuthContext.Provider
        value={{
          register,
          getValues,
          handleSubmit,
          reset,
          errors,
          showLogin,
          handleCloseLogin,
          handleShowLogin,
        }}
      >
        {children}
      </ClientAuthContext.Provider>
  )
}

    const useClientAuth = () => useContext(ClientAuthContext)
    
    export { ClientAuthProvider, useClientAuth }
    