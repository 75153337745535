import React from 'react';
import { createBrowserRouter, RouterProvider} from 'react-router-dom'
import App from '../../App';
import { formRouting } from './FormRouting';
import { authRoutes } from './AuthRouting';
import { useAuth } from '../modules/auth/core/Auth';
import { privateRoutes } from './PrivateRoutes';
import { Logout } from '../modules/auth/Logout';

function AppRoutes() {
  const {currentUser} = useAuth()
  const router = createBrowserRouter([
    {
      element: <App/>,
      children:[
          formRouting,
          currentUser && currentUser.is_superuser ? privateRoutes : authRoutes,
      ]
    },
    {
      path:'logout',
      element:<Logout />
    }
  ]);
  
  return (
        <RouterProvider router={router} />
  );
}
  
  export default AppRoutes;