import { NewOscFormWrapped } from "./new_osc_form/NewOscForm"
import { NewOscProvider } from "./_core/NewOscProvider"
import { NewOscQueryProvider } from "./_core/NewOscQueryProvider"
import { ClientAuthProvider } from "./_core/ClientAuthProvider"


const NewOscPage = () => {
    return (
        <NewOscQueryProvider>
            <ClientAuthProvider>
                <NewOscProvider>
                    <NewOscFormWrapped/>
                </NewOscProvider>
            </ClientAuthProvider>
        </NewOscQueryProvider>
    )
}

export {NewOscPage}