import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const TooltipForm = ({message}) => <Tooltip id="tooltip">{message}</Tooltip>

const InputForm = ({ label, placeholder, tooltip, name, type="text", errors, register, min, watch, ...rest}) => {

    let has_error = Object.keys(errors).length && errors[name]
    let error_message = ""
    const exceptThisSymbols = ["e", "E", "+", "-", ",", "."];

    // verifica erros para campos tipo array
    if(Object.keys(errors).length && name.includes("[")){
        const array_name = name.split("[")[0]
        if(errors[array_name]){
            const array_index = name.split("[")[1].split("]")[0]
            const field_name = name.split("[")[1].split("]")[1].replace(".", "")
            has_error = Object.prototype.hasOwnProperty.call(errors[array_name][array_index] || {}, field_name)
            if (has_error){
                error_message = errors[array_name][array_index][field_name].message
            }
        }
    }
    
    // verifica erros para campos tipo object
    if(Object.keys(errors).length && name.includes(".")){
        const base = name.split(".")[0]
        const field_name = name.split(".")[1]
        if(errors[base]){
            has_error = Object.prototype.hasOwnProperty.call(errors[base] || {}, field_name)
            if(has_error){
                error_message = errors[base][field_name].message
            }
            if(name.includes("[")){
                const array_name = field_name.split("[")[0]
                if(errors[base][array_name]){
                    const array_index = field_name.split("[")[1].split("]")[0]
                    if (errors[base][array_name][array_index]){
                        has_error = true
                        error_message = errors[base][array_name][array_index].message
                    }
                }
            }

        }
    }

    if (Object.keys(errors).length && errors[name]){
        error_message = errors[name]?.message
    }

    return (
        <Form.Group className="mb-3" controlId={name}>
            {label && (
                tooltip ?
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-text">{tooltip}</Tooltip>}>
                        <Form.Label className='fs-5'>{label}<i className="bi bi-exclamation-circle fs-4 text-muted" style={{ marginLeft: '8px'}}></i></Form.Label>
                    </OverlayTrigger>
                :
                    <Form.Label className='fs-5'>{label}</Form.Label>
                )
            }
                <Form.Control 
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    isInvalid={has_error}
                    onKeyDown={e => (type === "number") ? exceptThisSymbols.includes(e.key) && e.preventDefault() : {}}
                    {...register(name, (type === "number") ? {valueAsNumber: true} : {})}
                    {...rest}
                />
                {min && (
                    <span className={`text-end ${watch(name)?.length >= min ? 'text-success' : 'text-muted'}`}> {watch(name)?.length || 0} / minimo de {min} caracteres</span>
                )}

            <Form.Control.Feedback type="invalid">
                {error_message}
            </Form.Control.Feedback>
            
        </Form.Group>
    )
}

export {InputForm}