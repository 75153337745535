import { Col, Row } from "react-bootstrap";
import InputMask from 'react-input-mask';

import { InputForm } from "../../../../../_core/components/InputForm";


const ResponsableForm = ({errors, register, handlePhoneMaskChange}) => {
    return (
        <Row>
            <Col>
                <InputForm 
                    label="Nome"
                    name="responsable.name"
                    type="text"
                    placeholder="Insira o seu nome"
                    errors={errors}
                    register={register}
                />
            </Col>
            <Col>
                <InputForm 
                    label="Cargo"
                    name="responsable.role"
                    placeholder="Insira o seu cargo"
                    type="text"
                    errors={errors}
                    register={register}
                />
            </Col>
            <Col>
                <InputForm 
                    label="Email"
                    name="responsable.email"
                    placeholder="exemplo@email.com"
                    type="text"
                    errors={errors}
                    register={register}
                />
            </Col>
            <Col>
                <InputForm 
                    label="Telefone"
                    name="responsable.phone"
                    placeholder="Insira o seu telefone"
                    type="text"
                    mask={handlePhoneMaskChange()}
                    as={InputMask}
                    errors={errors}
                    register={register}
                />
            </Col>
        </Row>
    )
}

export {ResponsableForm}