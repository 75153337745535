import { Col, Container, Row } from "react-bootstrap"
import Button from 'react-bootstrap/Button';

import { useState } from 'react';

import { PrivacyModal } from "./PrivacyModal";
import { LoginModal } from "./LoginModal";
import { useAuth } from "../../../../../auth/core/Auth";
import { useClientAuth } from "../../../_core/ClientAuthProvider";


const TermsForm = ({setAcceptedTerms, setNewOscFormData}) => {
    const { currentUser } = useAuth()
    const { showLogin, handleCloseLogin, handleShowLogin } = useClientAuth()

    setNewOscFormData(null)
    
    return (
        <Container>
            <Row>
                <Col>
                    <p className="fs-3">
                        A Connecting Food é uma empresa de impacto social que conecta doadores de alimentos a Organizações Sociais que atendem pessoas em situação de vulnerabilidade, além de realizar a gestão completa do processo de doação.
                        <br />Nosso objetivo é criar uma rede sólida de apoio, ampliando o impacto social por meio da colaboração entre doadores e Organizações.
                    </p>
                    <h3>Como Participar:</h3>
                    <p className="fs-3">
                        - Cadastre sua Organização Social para habilitá-la a receber doações e integrar nossa rede de impacto.<br />
                        - O cadastro é essencial para garantirmos uma base de dados atualizada e eficiente, facilitando a conexão com potenciais doadores.<br />
                        <b>Importante:</b><br />
                        - O preenchimento do formulário é o primeiro passo do processo e não garante imediatamente uma parceria ou doações.<br />
                        - Caso sua Organização já seja nossa parceira, utilize o formulário para atualizar seus dados cadastrais e manter a regularidade da parceria.
                    </p>
                    <h3>Por que é importante preencher com atenção?</h3>
                    <p className="fs-3">
                        Os dados fornecidos nos ajudam a:<br />
                        - <b>Demonstrar o impacto das doações</b> realizadas.<br />
                        - <b>Engajar mais doadores</b>, ampliando o alcance da nossa rede de solidariedade.<br />
                        Para mais informações sobre nosso trabalho, acesse: <a href="https://connectingfood.com" target="_blank">www.connectingfood.com</a><br />
                        Preencha o formulário e faça parte da nossa missão de transformação social! 🧡
                    </p>
                </Col>
            </Row>
            
            <hr></hr>
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    {!currentUser && (
                        <Button variant="outline-primary" onClick={() => handleShowLogin()}>
                            Continuar Cadastro
                        </Button>
                    )}
                    <Button variant="success" onClick={() => setAcceptedTerms(true)} className="ms-1">
                        {!currentUser ? 'Iniciar Cadastro' : 'Continuar Cadastro'}
                    </Button>
                </Col>
            </Row>
        <LoginModal showState={showLogin} handleCloseState={handleCloseLogin} />
        </Container>
    )
}

export {TermsForm}