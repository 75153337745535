import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import InputMask from 'react-input-mask';

import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { Col, Container, Row } from 'react-bootstrap';
import { InputForm } from '../../../../../../_core/components/InputForm';
import { useClientAuth } from '../../../_core/ClientAuthProvider';
import { useState } from 'react';
import { getUserByToken, login } from '../../../../../auth/core/_requests';
import { useAuth } from '../../../../../auth/core/Auth';


const LoginModal = ({showState, handleCloseState}) => {
    const { register, getValues, errors, handleSubmit, reset } = useClientAuth()
    const { saveAuth, setCurrentUser } = useAuth()
    
    const [loading, setLoading] = useState(false)
    const [loginFail, setLoginFail] = useState(false)
    
    const handleSendAuth = async () =>{
        const values = getValues()
        setLoading(true)
        try {
            const { data: auth } = await login(values.cnpj.replace("/", "-"), values.password)
            saveAuth(auth)
            const { data: user } = await getUserByToken(auth.access)
            setCurrentUser(user)
            setLoading(false)
            handleCloseState()
        } catch (error) {
            setLoginFail(true)
            saveAuth(undefined)
            setLoading(false)
        }
        reset({cnpj: "", password: ""})
        // handleCloseState()
    }

    const handlecloseModal = () =>{
        setLoading(false)
        setLoginFail(false)
        handleCloseState()
    }

    return (
        <Modal show={showState} onHide={handleCloseState} backdrop="static" keyboard={false} size="lg">
            <Modal.Header className='d-flex justify-content-center'>
            <Image
                src={toAbsoluteUrl("/media/svg/misc/CF-favicon.svg")}
                fluid
                style={{maxWidth: "20%"}}
            />
            </Modal.Header>
            <Modal.Body>
                <Container className='m-2'>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            <p className='fs-3 fw-bolder'>Login</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputForm 
                                placeholder="Insira o cnpj"
                                name="cnpj"
                                type="text"
                                mask="99.999.999/9999-99"
                                as={InputMask}
                                errors={errors}
                                register={register}
                                disabled={loading}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputForm
                                name={`password`}
                                placeholder="Insira a senha"
                                type="password"
                                errors={errors}
                                register={register}
                                disabled={loading}
                            />
                        </Col>
                    </Row>
                    {loginFail && (
                        <Row>
                            <Col className='alert alert-danger d-flex justify-content-center'>
                                <div className='alert-text font-weight-bold'>Usuario ou senha invalidos!</div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
            <Button variant="primary" onClick={handleSubmit(() => handleSendAuth())} disabled={loading}>
                {loading ? ("Carregando...") : ("Login")}
            </Button>
            <Button variant="info" onClick={handlecloseModal}>
                Cancelar
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export {LoginModal}