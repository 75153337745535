import { Card } from "react-bootstrap"
import Image from 'react-bootstrap/Image';

import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import { KTSVG } from "../../../../../_metronic/helpers"

import { CreationForm } from "./form/CreationForm"
import { TermsForm } from "./form/TermsForm/TermsForm"
import { useNewOsc } from "../_core/NewOscProvider";
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from "react";
import { PrivacyModal } from "./form/TermsForm/PrivacyModal";

const NewOscFormWrapped = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    const {acceptedTerms, setAcceptedTerms, setNewOscFormData, showInfoToast} = useNewOsc()
    useEffect(() => {
        if(showInfoToast){
            toast.info("Ja existe uma conta com esse cnpj, faça login para continuar.")
        }
    }, [showInfoToast])
    return (
        <>
            <Card>
                <Card.Header className="d-flex justify-content-center p-2" style={{backgroundColor: "rgba(179, 91, 39, 0.1)"}}>
                    <Image
                        src={toAbsoluteUrl("/media/avatars/connecting-food-logo-480x119.webp")}
                        fluid
                        style={{maxWidth: "240px"}}
                    />
                </Card.Header>
                <Card.Body style={{backgroundColor: "rgba(0, 0, 0, 0.1)"}}>
                    {acceptedTerms ? (
                        <CreationForm/>
                    ): (
                        <TermsForm setAcceptedTerms={setAcceptedTerms} setNewOscFormData={setNewOscFormData}/>
                    )}
                </Card.Body>
                <Card.Footer className="" style={{backgroundColor: "rgba(253, 128, 62, 1)", padding: '10px 0', textAlign: 'center', color: '#fff'}}>
                   Este cadastro segue as diretrizes da LGPD (Lei nº 13.709/2018) para garantir a segurança das suas informações.
                    <br />Saiba mais na nossa <span variant="link" onClick={handleShow} style={{cursor: 'pointer', textDecoration: 'underline'}}>Política de Privacidade</span>
                </Card.Footer>
            </Card>
            <PrivacyModal showState={show} handleCloseState={handleClose} />
            <ToastContainer/>
        </>

    )
}

export {NewOscFormWrapped}