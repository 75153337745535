import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Col, Row } from "react-bootstrap";

import { useNewOsc } from "../../_core/NewOscProvider"
import { GeneralDataStep } from './steps/GeneralDataStep';
import { CompanyProfileStep } from './steps/CompanyProfileStep';
import { InstitutionalStep } from './steps/InstitutionalStep';
import { TargetPublicStep } from './steps/TargetPublicStep';
import { FoodManagmentStep } from './steps/FoodManagmentStep';
import { ComplementarInformationStep } from './steps/ComplementarInformationStep';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useAuth } from '../../../../auth/core/Auth';

const CreationForm = () => {

    const [currentStep, setCurrentStep] = useState(0)
    const [currentStepPercentage, setCurrentStepPercentage] = useState(0)

    const {
        handleSubmit,
        sendTest,
        useCreateNewOscForm,
        trigger,
        reset,
        setAcceptedTerms,
        setSwalProps,
        preSave,
        usePreSaveNewOscForm,
        useCachedNewOscForm,
        setValue,
        showFinalSuccessSwal,
        setShowFinalSuccessSwal,
        preSaveLoading
    } = useNewOsc()

    const { currentUser } = useAuth()
    
    const { isLoading: isLoadingSendForm, refetch, isError, isSuccess } = useCreateNewOscForm()
    
    const { data: dataCache } = useCachedNewOscForm()
    
    const { isLoading: isLoadingPreSave } = usePreSaveNewOscForm()

    const stepsList = [
        <GeneralDataStep/>,
        <CompanyProfileStep/>,
        <InstitutionalStep/>,
        <TargetPublicStep/>,
        <FoodManagmentStep/>,
        <ComplementarInformationStep/>
    ]

    const stepsCheck = [
        ["cnpj", "cep", "street_number", "phone", "organization_site", "social_midia", "priority_category", "responsable"],
        ["activities_detail", "quantity_workers", "quantity_volunteers", "quantity_people_served_monthly", "recess_months_create"],
        ["has_public_agreement", "has_specific_agreement", "has_updated_cnpj", "has_updated_member_registration", "has_updated_minute_inauguration_assembly", "has_updated_health_permit"],
        [
            "target_audience_create", 
            "age_group__id", 
            "age_group__quantity_people_served_monthly1", 
            "age_group__quantity_people_served_monthly2",
            "age_group__quantity_people_served_monthly3",
            "age_group__quantity_people_served_monthly4",
            "age_group__quantity_people_served_monthly5",
            "people_benefited",
            "has_registration_form",
        ],
        [
            "has_adequate_space",
            "has_nutritionist",
            "has_freezing_system",
            "has_local_kitchen",
            "has_refectory",
            "serve_local_meals",
            "meal_type",
            "food_distribution",
            "educational_usage",
        ],
        ["other_parters", "donation_extraction"]
    ]

    const stepsPreSave = [
        true,
        true,
        true,
        true,
        true,
        true,
    ]

    const handleChangeStep = async (step) => {
        let canPassStep = true
        if (step > currentStep){
            canPassStep = await trigger(stepsCheck[currentStep])
        }
        if (canPassStep){
            if (stepsPreSave[currentStep]){
                await preSave(currentStep === 0)
            }
            if(!isLoadingPreSave){
                setCurrentStep(step)
                let progressBarPercentage = 100 / (stepsList.length - parseInt(step)) 
                if (step === 0){
                    progressBarPercentage = 0
                }
                setCurrentStepPercentage(progressBarPercentage)
            }
        }
    }
    
    const MySwal = withReactContent(Swal)
    
    if(isLoadingSendForm){
        MySwal.showLoading()
    }else{
        MySwal.hideLoading()
    }

    if(isSuccess || showFinalSuccessSwal){
        MySwal.fire({
            width: 600,
            icon:"success",
            title: <p>Sua Resposta foi Enviada com sucesso!</p>,
            html: <>
                <p className='fs-4 fw-bolder'>
                    Quer saber mais sobre nosso trabalho e acompanhar as ações de perto?
                    Siga-nos em nossas redes sociais, estamos no Instagram, Facebook e Linkedin! 
                </p>
                <p  className='fs-5 fw-bolder'>
                    Nos vemos lá!
                </p>
                <p className='fs-5 text-center'>
                    <a href='https://www.instagram.com/connectingfoodbrasil/' className='text-info'> https://www.instagram.com/connectingfoodbrasil/ </a>
                    <a href='https://www.facebook.com/connectingfoodbrasil' className='text-info'> https://www.facebook.com/connectingfoodbrasil </a>
                    <a href='https://www.linkedin.com/company/connecting-food-brasil/' className='text-info'> https://www.linkedin.com/company/connecting-food-brasil/ </a>
                    <a href='https://www.connectingfood.com' className='text-info'> https://www.connectingfood.com </a>
                </p>
                <p className='fs-5 fw-bolder'>
                    Muito Obrigado!
                </p>
            </>,
            didClose: () => {
                handleChangeStep(0)
                setSwalProps({});
                setAcceptedTerms()
                reset()
            }
        })
    }

    if(isError){
        MySwal.fire({
            width: 600,
            icon:"error",
            title: <p>Erro ao registrar resposta!</p>,
            html: <>
                <p className='fs-4 fw-bold'>
                    Desculpe, algo deu errado ao tentar enviar seu formulário.
                    Por favor, envie um e-mail para <b>ti@connectingfood.com</b> para que possamos ajudar a resolver o problema.
                </p>
            </>,
            didClose: () => {
                handleChangeStep(0)
                setSwalProps({});
                setAcceptedTerms()
                reset()
            }
        })
    }
    useEffect(() => {
        if(dataCache){
            console.log(dataCache)
            Object.keys(dataCache).forEach((key) => {
                setValue(key, dataCache[key])
            })
        }    
    }, [dataCache])

    useEffect(() => {
        if(currentUser && currentUser.form_answer.length && !showFinalSuccessSwal){
            setShowFinalSuccessSwal(true)
        }
    }, [currentUser])

    const handleSendForm = () => {
        sendTest(refetch)
    }

    return (
        <>
            <div className='progress h-5px w-100'>
                <div
                className={`progress-bar bg-primary`}
                role='progressbar'
                style={{width: `${currentStepPercentage}%`}}
                ></div>
            </div>
                
            {stepsList[currentStep]}
                

            <Row className='d-flex justify-content-end'>
                <Col className='d-flex justify-content-end' style={{ marginTop: '15px'}}>
                    {currentStep > 0 && (
                        <Button variant="outline-primary" onClick={() => {handleChangeStep(currentStep - 1)}} className='m-2'>
                            Voltar
                        </Button>
                    )}
                    {(currentStep === stepsList.length - 1)? (
                        <Button variant="success" onClick={handleSubmit(() => handleSendForm())} type="submit" className='m-2'>
                            Enviar
                        </Button>
                    ) : (
                        <Button variant="primary" disabled={preSaveLoading} onClick={() => {handleChangeStep(currentStep + 1)}} className='m-2'>
                            {preSaveLoading ? "Carregando..." : "Salvar e avançar"}
                        </Button>
                    )}
                </Col>

                {/* <SweetAlert2 className="swal2-container" {...swalProps} didClose={() => {
                    
                }}/> */}
            </Row>
        </>
    )
}

export {CreationForm}